export class GraphqlError extends Error {
	constructor(errors) {
		let message = 'Unknown error'
		if (Array.isArray(errors)) {
			message = errors[0].message
		}

		super(message)

		this.errors = errors
	}
}
