import { defineStore } from 'pinia'

import { getLoginUrl } from '@/stores/get-login-url.js'
import queryUserData from '@/stores/query-user-data.js'

const useCurrentUserStore = defineStore('currentUser', {
	state: () => ({
		user: null,
		loginError: null,
	}),
	getters: {
		isLoggedIn: (state) => !!state.user,
		hasMatchingRole: (state) => (roles) => {
			if (!state.user) return false

			if (!state.user.roles || !Array.isArray(state.user.roles)) return false

			return roles.some((role) => state.user.roles.includes(role))
		},
	},
	actions: {
		/**
		 * @param {string} redirectUrl - where to redirect after login
		 * @returns {Promise<void>}
		 */
		async login(redirectUrl = '/') {
			this.user = await queryUserData().catch((error) => {
				if (needToLoginError(error)) return null

				throw error
			})

			if (!this.user) {
				redirectToLogin(redirectUrl)
				return
			}

			this.router.push({
				path: redirectUrl,
			})
		},
	},
})

export default useCurrentUserStore

function redirectToLogin(redirectUrl) {
	window.location.href = getLoginUrl(redirectUrl)
}

function needToLoginError(error) {
	if (error.response && error.response.errors) {
		if (error.response.status === 401) return true

		const firstError = error.response.errors[0]

		if (firstError.code === 'FST_JWT_INVALID_SIGNATURE') return true
	}

	return false
}
