import { GRAPHQL_ENDPOINT } from '@/config.js'
import { GraphQLClient } from 'graphql-request'

import { GraphqlError } from './errors/graphql-error.js'

/**
 * @returns {import('graphql-request').GraphQLClient}
 */
export default function graphqlClient() {
	return new GraphQLClient(GRAPHQL_ENDPOINT, {
		credentials: 'include',
		mode: 'cors',
		responseMiddleware: (response) => {
			if (response instanceof Error) {
				if (response.response && response.response.status === 200 && response.response.errors) {
					throw new GraphqlError(response.response.errors)
				}

				throw response
			}

			return response
		},
	})
}
