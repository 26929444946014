import { gql } from 'graphql-request'

import graphqlClient from '@/graphql/graphql-client.js'

const QUERY = gql`
	query me {
		me {
			user {
				id
				firstName
				lastName
				email
				role
			}
		}
	}
`

export default function queryUserData() {
	return graphqlClient()
		.request(QUERY)
		.then((response) => ({
			...response.me.user,
			roles: [response.me.user.role],
		}))
}
