<template>
	<header>
		<Menubar :model="items">
			<template #start>
				<router-link to="/">
					<img alt="logo" src="/images/voiz-logo.svg" height="25" class="voiz-logo mr-2" />
				</router-link>
			</template>
			<template #item="{ label, item, props, root }">
				<router-link v-if="item.to" v-slot="routerProps" :to="item.to" custom>
					<a :href="routerProps.href" v-bind="props.action">
						<span v-bind="props.icon" />
						<span v-bind="props.label">{{ label }}</span>
					</a>
				</router-link>
				<a v-else :href="item.url" :target="item.target" v-bind="props.action">
					<span v-bind="props.icon" />
					<span v-bind="props.label">{{ label }}</span>
					<span
						:class="[root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right']"
						v-bind="props.submenuicon"
					/>
				</a>
			</template>
		</Menubar>
	</header>
</template>

<script setup>
import { ref } from 'vue'

const items = ref([
	{
		label: 'Tartalom',
		items: [
			{
				label: 'Tartalmak',
				to: '/content/contents',
			},
			{
				label: 'Felolvasók',
				to: '/content/narrators',
			},
			{
				label: 'Kategóriák',
				to: '/content/categories',
			},
			{
				label: 'Kiadók',
				to: '/content/publishers',
			},
			{
				label: 'Szerzők',
				to: '/content/authors',
			},
		],
	},
])
</script>

<style scoped>
.voiz-logo {
	margin: 0.25rem 0 0 0.5rem;
}
</style>
