import './scss/main.scss'

import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'

import App from './App.vue'
import router from './router'

const app = createApp(App)

const pinia = createPinia()
pinia.use(({ store }) => {
	store.router = markRaw(router)
})

app.component('InputText', () => import('@/components/InputText.vue'))
app.component('InputTextarea', () => import('@/components/InputTextarea.vue'))
app.use(pinia)
app.use(router)
app.use(PrimeVue)
app.use(ConfirmationService)
app.use(ToastService)

app.mount('#app')
