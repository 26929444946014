import { WEBSITE_LOGIN_URL } from '@/config.js'

/**
 * @param {string} toFullPath - vue router to.fullPath
 */
export function getLoginUrl(toFullPath) {
	const url = new URL(WEBSITE_LOGIN_URL)
	url.searchParams.set('layout', 'wizard')
	url.searchParams.set('redirectUrl', getRedirectUrl(toFullPath))

	return url.toString()
}

/**
 * @param {string} toFullPath - vue router to.fullPath
 */
function getRedirectUrl(toFullPath) {
	const { hostname, port, protocol } = window.location

	const url = new URL(toFullPath, `${protocol}//${hostname}:${port}`)
	// The website does not allow direct redirection to external domains as redirect-url query param
	// of /auth/auth-selector. So we need to redirect first to the /auth/external-redirect
	// and it will redirect back to the admin portal
	return '/auth/external-redirect?redirect-url=' + encodeURIComponent(url.toString())
}
