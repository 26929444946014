import useCurrentUserStore from '@/stores/current-user.js'

/**
 * @param {import('vue-router').RouteLocationNormalized} to
 */
export default function hasRoleGuard(to) {
	if (!to.meta.roles || to.meta.roles.length === 0) return true

	const currentUserStore = useCurrentUserStore()
	const isLoggedIn = currentUserStore.isLoggedIn

	if (!isLoggedIn)
		return {
			name: 'auth-login',
			query: {
				redirectUrl: to.fullPath,
			},
		}

	const hasMatchingRole = currentUserStore.hasMatchingRole(to.meta.roles)
	if (!hasMatchingRole)
		return {
			name: 'not-authorized',
		}

	return true
}
