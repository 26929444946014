<script setup>
import { onMounted } from 'vue'
import useCurrentUserStore from '@/stores/current-user.js'

const props = defineProps({
	redirectUrl: {
		type: String,
		default: '/',
	},
})

onMounted(async () => {
	const currentUserStore = useCurrentUserStore()
	await currentUserStore.login(props.redirectUrl)
})
</script>

<template>
	<h1>Bejelentkezés</h1>
	<p>Felhasználói adatok lekérdezése</p>
</template>
