import { createRouter, createWebHistory } from 'vue-router'

import { USER_ROLES } from '@/constants.js'
import hasRoleGuard from '@/router/has-role-guard.js'
import LoginView from '@/views/auth/LoginView.vue'
import HomeView from '@/views/HomeView.vue'
import NotAuthorized from '@/views/NotAuthorized.vue'
import NotFound from '@/views/NotFound.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView,
		},
		{
			path: '/auth/login',
			name: 'auth-login',
			component: LoginView,
			props: (route) => ({ redirectUrl: route.query.redirectUrl }),
		},
		{
			path: '/not-authorized',
			name: 'not-authorized',
			component: NotAuthorized,
		},
		{
			path: '/content/categories',
			name: 'content-categories',
			component: () => import('@/views/content/CategoriesView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/authors',
			name: 'content-authors',
			component: () => import('@/views/content/AuthorsView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/authors/new',
			name: 'content-authors-new',
			component: () => import('@/views/content/AuthorsEditView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/authors/edit/:slug',
			name: 'content-authors-edit',
			component: () => import('@/views/content/AuthorsEditView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/narrators',
			name: 'content-narrators',
			component: () => import('@/views/content/NarratorsView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/narrators/new',
			name: 'content-narrators-new',
			component: () => import('@/views/content/NarratorsEditView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/narrators/edit/:slug',
			name: 'content-narrators-edit',
			component: () => import('@/views/content/NarratorsEditView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/publishers',
			name: 'content-publishers',
			component: () => import('@/views/content/PublishersView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/publishers/new',
			name: 'content-publishers-new',
			component: () => import('@/views/content/PublishersEditView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/publishers/edit/:slug',
			name: 'content-publishers-edit',
			component: () => import('@/views/content/PublishersEditView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/contents',
			name: 'content-contents',
			component: () => import('@/views/content/ContentsView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/contents/new',
			name: 'content-contents-new',
			component: () => import('@/views/content/ContentsEditView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/content/contents/edit/:slug',
			name: 'content-contents-edit',
			component: () => import('@/views/content/ContentsEditView.vue'),
			meta: {
				roles: [USER_ROLES.ADMIN],
			},
		},
		{
			path: '/:pathMatch(.*)*',
			name: 'not-found',
			component: NotFound,
		},
	],
})

router.beforeEach(hasRoleGuard)

export default router
